<template>
    <div class='d-flex flex-column justify-content-center align-items-center'>
        <h2 class="mb-1">
          Ошибки доступа
        </h2>
        <p class="mb-2">
          У Вас нет прав доступа к этому модулю
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path:'/administrator' }"
        >
          На главную
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
    </div>
</template>

<script>
    
    import store from '@/store/index'

    export default {

        data() {
            return {
                downImg: require('@/assets/images/pages/error.svg'),
            }
        },
        methods: {

        },
        computed: {
            imgUrl() {
              if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require('@/assets/images/pages/error-dark.svg')
                return this.downImg
              }
              return this.downImg
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>